import React, { Component } from 'react';
import ProjectsMenu from './ProjectsMenu';

export default class Projects extends Component {
  render() {
    console.log('hello');
    return (
      <>
        <ProjectsMenu />
      </>
    );
  }
}
