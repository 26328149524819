import React from 'react'
import "../styles/playerStats.css"

export default (function PlayerStats() {
  return (
    <div id="playerStats">
        <h1>Krystle Mitchell Level 33</h1>
        <div id="playerStats_lines">
            <span id="playerStats_lines_thick"></span>
            <span id="playerStats_lines_thin"></span>
           
        </div>
        <h3>Fullstack Developer</h3>


      
    </div>
  )
})
